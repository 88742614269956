<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-row style="margin-top: 4px; margin-left: 10px; margin: auto">
            <v-col cols="4" lg="2" md="2">
              <s-text
                label="Ingrese año inicio"
                number
                v-model="txtYearInitial"
              ></s-text>
            </v-col>
            <v-col cols="4" lg="2" md="2">
              <s-text
                label="Ingrese año fin"
                number
                v-model="txtYearFin"
              ></s-text>
            </v-col>
            <v-col sm="6" cols="6" lg="3" md="3">
              <s-select-definition
                :def="1215"
                v-model="objMounth"
                return-object
                label="Seleccione periodo"
              ></s-select-definition>
            </v-col>
            <v-col cols="4" lg="2" md="2" class="mt-3 pl-0">
              <v-btn width="100%" rounded :color="'info'" small @click="run()"
                >Buscar</v-btn
              >
            </v-col>
            <v-col cols="4" lg="2" md="2" class="mt-3 pl-0">
              <v-spacer></v-spacer>
              <v-btn @click="downloadOne()" rounded :color="'warning'" small>
                Descargar
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="processing"
      :persistent="messageProcessing.length == 0"
      width="400"
    >
      <v-card color="primary" dark>
        <v-card-text v-if="messageProcessing.length == 0">
          Por favor espere
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
        <v-card-text v-else>
          <div class="text--white pt-4">
            Error al realizar busqueda <br />
            {{ messageProcessing }}
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-tabs v-model="currentItem" class="mt-4">
      <v-tab href="#DashboardCV"> Dashboard CV</v-tab>

      <v-tab href="#GeneralCv"> General CV</v-tab>
      <v-tab href="#Cv">Cv</v-tab>
      <v-tab href="#DashboardRe"> Dashboard RE</v-tab>
      <v-tab href="#Re">RE</v-tab>
      <v-tab href="#DashboardReFecVta"> Dashboard Re Fecha Venta</v-tab>
      <v-tab href="#ReFecVta">RE Fecha Venta</v-tab>
      <v-tab href="#DashboardBruta">Dashboard Bruta</v-tab>
      <v-tab href="#Bruta">Bruta</v-tab>
      <v-tab href="#Salable">Disponible</v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentItem">
      <v-tab-item :value="'DashboardCV'">
        <clc-report-dasboard-price-meter
          :itemsLot="dataDashboardLoteCv"
          :objDrilldownLot="objDrilldownLoteCv"
          :itemsArea="dataDashboardAreaCv"
          :objDrilldownArea="objDrilldownAreaCv"
          :itemsCapital="dataDashboardCapitalCv"
          :objDrilldownCapital="objDrilldownCapitalCv"
          :itemsPricePerMeter="dataDashboardPricePerMeterCv"
          :objDrilldownPricePerMeter="objDrilldownPricePerMeterCv"
          :ids="idsCv"
        >
        </clc-report-dasboard-price-meter>
      </v-tab-item>

      <v-tab-item :value="'GeneralCv'">
        <v-row>
          <v-spacer />
          <v-col cols="6">
            <v-text-field
              v-model="searcher.generalCV"
              append-icon="mdi-magnify"
              label="Cuadrante, lote, Dni"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <gral-table
              :headers="headersGeneral"
              :items="itemsGeneral"
              :propsdinamic="{
                search: searcher.generalCV,
                class: 'fixed-first-column',
              }"
              :columnsNotNumber="['Cod.venta', 'Año venta', 'Año resolucion']"
            ></gral-table>
          </v-col>
        </v-row>
        <!-- </v-col> -->
      </v-tab-item>

      <v-tab-item :value="'Salable'">
        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <gral-table
                :headers="headerSalable"
                :items="itemsSalable"
                :propsdinamic="{
                  class: 'fixed-first-column',
                }"
              ></gral-table>
            </v-col>
          </v-row>
        </v-col>
      </v-tab-item>

      <v-tab-item :value="'ReFecVta'">
        <v-row>
          <v-col cols="6"><h2 class="subheading">Lote</h2> </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="searcher.batchReFv"
              append-icon="mdi-magnify"
              label="Cuadrante, lote, Dni"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <gral-table
              :headers="headersBatchReFv"
              :items="itemsBatchReFv"
              :propsdinamic="{
                class: 'fixed-first-column',
                search: searcher.batchReFv,
              }"
              :columnsNotNumber="['Anioventa']"
            ></gral-table>
          </v-col>
        </v-row>

        <v-divider :class="'pa-2'"></v-divider>
        <v-row>
          <v-col cols="6"><h2 class="subheading">Area</h2> </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="searcher.areaReFv"
              append-icon="mdi-magnify"
              label="Cuadrante, lote, Dni"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <gral-table
              :headers="headersAreaReFv"
              :items="itemsAreaReFv"
              :propsdinamic="{
                class: 'fixed-first-column',
                search: searcher.areaReFv,
              }"
              :columnsNotNumber="['Anioventa']"
            ></gral-table>
          </v-col>
        </v-row>

        <v-divider :class="'pa-2'"></v-divider>
        <v-row>
          <v-col cols="6"><h2 class="subheading">Capital</h2> </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="searcher.capitalReFv"
              append-icon="mdi-magnify"
              label="Cuadrante, lote, Dni"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <gral-table
              :headers="headersCapitalReFv"
              :items="itemsCapitalReFv"
              :propsdinamic="{
                class: 'fixed-first-column',
                search: searcher.capitalReFv,
              }"
              :columnsNotNumber="['Anioventa']"
            ></gral-table>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item :value="'DashboardReFecVta'">
        <clc-report-dasboard-gral
          :itemsLot="dataDashboardLoteReFv"
          :objDrilldownLot="objDrilldownLoteReFv"
          :itemsArea="dataDashboardAreaReFv"
          :objDrilldownArea="objDrilldownAreaReFv"
          :itemsCapital="dataDashboardCapitalReFv"
          :objDrilldownCapital="objDrilldownCapitalReFv"
          :ids="idsReFv"
        >
        </clc-report-dasboard-gral>
      </v-tab-item>
      <v-tab-item :value="'DashboardRe'">
        <clc-report-dasboard-gral
          :itemsLot="dataDashboardLoteRe"
          :objDrilldownLot="objDrilldownLoteRe"
          :itemsArea="dataDashboardAreaRe"
          :objDrilldownArea="objDrilldownAreaRe"
          :itemsCapital="dataDashboardCapitalRe"
          :objDrilldownCapital="objDrilldownCapitalRe"
          :ids="idsRe"
        >
        </clc-report-dasboard-gral>
      </v-tab-item>
      <v-tab-item :value="'Bruta'">
        <v-row>
          <v-col cols="6"><h2 class="subheading">Lote</h2> </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="searcher.batchBruta"
              append-icon="mdi-magnify"
              label="Cuadrante, lote, Dni"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <gral-table
              :headers="headersBatchBruta"
              :items="itemsBatchBruta"
              :propsdinamic="{
                class: 'fixed-first-column',
                search: searcher.batchBruta,
              }"
              :columnsNotNumber="['Anioventa']"
            ></gral-table>
          </v-col>
        </v-row>

        <v-divider :class="'pa-2'"></v-divider>
        <v-row>
          <v-col cols="6"><h2 class="subheading">Area</h2> </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="searcher.areaBruta"
              append-icon="mdi-magnify"
              label="Cuadrante, lote, Dni"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <gral-table
              :headers="headersAreaBruta"
              :items="itemsAreaBruta"
              :propsdinamic="{
                class: 'fixed-first-column',
                search: searcher.areaBruta,
              }"
              :columnsNotNumber="['Anioventa']"
            ></gral-table>
          </v-col>
        </v-row>

        <v-divider :class="'pa-2'"></v-divider>
        <v-row>
          <v-col cols="6"><h2 class="subheading">Capital</h2> </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="searcher.capitalBruta"
              append-icon="mdi-magnify"
              label="Cuadrante, lote, Dni"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <gral-table
              :headers="headersAreaBruta"
              :items="itemsAreaBruta"
              :propsdinamic="{
                class: 'fixed-first-column',
                search: searcher.capitalBruta,
              }"
              :columnsNotNumber="['Anioventa']"
            ></gral-table>
          </v-col>
        </v-row>
        <v-divider :class="'pa-2'"></v-divider>
        <v-row>
          <v-col cols="6"><h2 class="subheading">Precio Promedio</h2> </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="searcher.pricePerMeterBruta"
              append-icon="mdi-magnify"
              label="Cuadrante, lote, Dni"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <gral-table
              :headers="headersPricePerMeterBruta"
              :items="itemsPricePerMeterBruta"
              :propsdinamic="{
                class: 'fixed-first-column',
                search: searcher.capitalBruta,
              }"
              :columnsNotNumber="['Anioventa']"
            ></gral-table>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item :value="'Cv'">
        <v-row>
          <v-col cols="6"><h2 class="subheading">Lote</h2> </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="searcher.batchCv"
              append-icon="mdi-magnify"
              label="Cuadrante, lote, Dni"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <gral-table
              :headers="headersBatchCv"
              :items="itemsBatchCv"
              :propsdinamic="{
                class: 'fixed-first-column',
                search: searcher.batchCv,
              }"
              :columnsNotNumber="['Anioventa']"
            ></gral-table>
          </v-col>
        </v-row>

        <v-divider :class="'pa-2'"></v-divider>
        <v-row>
          <v-col cols="6"><h2 class="subheading">Area</h2> </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="searcher.areaCv"
              append-icon="mdi-magnify"
              label="Cuadrante, lote, Dni"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <gral-table
              :headers="headersAreaCv"
              :items="itemsAreaCv"
              :propsdinamic="{
                class: 'fixed-first-column',
                search: searcher.areaCv,
              }"
              :columnsNotNumber="['Anioventa']"
            ></gral-table>
          </v-col>
        </v-row>

        <v-divider :class="'pa-2'"></v-divider>
        <v-row>
          <v-col cols="6"><h2 class="subheading">Capital</h2> </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="searcher.capitalCv"
              append-icon="mdi-magnify"
              label="Cuadrante, lote, Dni"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <gral-table
              :headers="headersCapitalCv"
              :items="itemsCapitalCv"
              :propsdinamic="{
                class: 'fixed-first-column',
                search: searcher.capitalCv,
              }"
              :columnsNotNumber="['Anioventa']"
            ></gral-table>
          </v-col>
        </v-row>
        <v-divider :class="'pa-2'"></v-divider>
        <v-row>
          <v-col cols="6"><h2 class="subheading">Precio Promedio</h2> </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="searcher.pricePerMeterCv"
              append-icon="mdi-magnify"
              label="Cuadrante, lote, Dni"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <gral-table
              :headers="headersPricePerMeterCv"
              :items="itemsPricePerMeterCv"
              :propsdinamic="{
                class: 'fixed-first-column',
                search: searcher.pricePerMeterCv,
              }"
              :columnsNotNumber="['Anioventa']"
            ></gral-table>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item :value="'Re'">
        <v-row>
          <v-col cols="6"><h2 class="subheading">Lote</h2> </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="searcher.batchRe"
              append-icon="mdi-magnify"
              label="Cuadrante, lote, Dni"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <!-- <v-data-table
              :search="searcher.batchRe"
              :height="'auto'"
              :headers="headersBatchRe"
              :items="itemsBatchRe"
              dense
              :footer-props="{
                showCurrentPage: true,
                showFirstLastPage: true,
                itemsPerPageOptions: [5, 10, 50, 100],
              }"
              class="fixed-first-column"
            >
            </v-data-table> -->
            <gral-table
              :headers="headersBatchRe"
              :items="itemsBatchRe"
              :propsdinamic="{
                class: 'fixed-first-column',
                search: searcher.batchRe,
              }"
              :columnsNotNumber="['Anioventa']"
            ></gral-table>
          </v-col>
        </v-row>

        <v-divider :class="'pa-2'"></v-divider>
        <v-row>
          <v-col cols="6"><h2 class="subheading">Area</h2> </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="searcher.areaRe"
              append-icon="mdi-magnify"
              label="Cuadrante, lote, Dni"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <!-- <v-data-table
              :search="searcher.areaRe"
              :height="'auto'"
              :headers="headersAreaRe"
              :items="itemsAreaRe"
              dense
              :footer-props="{
                showCurrentPage: true,
                showFirstLastPage: true,
                itemsPerPageOptions: [5, 10, 50, 100],
              }"
              class="fixed-first-column"
            >
            </v-data-table> -->
             <gral-table
              :headers="headersAreaRe"
              :items="itemsAreaRe"
              :propsdinamic="{
                class: 'fixed-first-column',
                search: searcher.areaRe,
              }"
              :columnsNotNumber="['Anioventa']"
            ></gral-table>
          </v-col>
        </v-row>

        <v-divider :class="'pa-2'"></v-divider>
        <v-row>
          <v-col cols="6"><h2 class="subheading">Capital</h2> </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="searcher.capitalRe"
              append-icon="mdi-magnify"
              label="Cuadrante, lote, Dni"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <!-- <v-data-table
              :search="searcher.capitalRe"
              :height="'auto'"
              :headers="headersCapitalRe"
              :items="itemsCapitalRe"
              dense
              :footer-props="{
                showCurrentPage: true,
                showFirstLastPage: true,
                itemsPerPageOptions: [5, 10, 50, 100],
              }"
              class="fixed-first-column"
            >
            </v-data-table> -->
             <gral-table
              :headers="headersCapitalRe"
              :items="itemsCapitalRe"
              :propsdinamic="{
                class: 'fixed-first-column',
                search: searcher.capitalRe,
              }"
              :columnsNotNumber="['Anioventa']"
            ></gral-table>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item :value="'DashboardBruta'">
        <clc-report-dasboard-price-meter
          :itemsLot="dataDashboardLoteBruta"
          :objDrilldownLot="objDrilldownLoteBruta"
          :itemsArea="dataDashboardAreaBruta"
          :objDrilldownArea="objDrilldownAreaBruta"
          :itemsCapital="dataDashboardCapitalBruta"
          :objDrilldownCapital="objDrilldownCapitalBruta"
          :itemsPricePerMeter="dataDashboardPricePerMeterBruta"
          :objDrilldownPricePerMeter="objDrilldownPricePerMeterBruta"
          :ids="idsBruta"
        >
        </clc-report-dasboard-price-meter>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import _sClcService from "@/services/ReportClicks/ClcChargeMasive.js";
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
import * as XLSX from "xlsx";

import ClcReportDasboard from "./ClcReportDasboard.vue";
import ClcReportDasboardDrill from "./ClcReportDasboardDrill.vue";
import ClcReportDasboardGral from "./ClcReportDasboardGral.vue";
import ClcReportDasboardPriceMeter from "./ClcReportDasboardPriceMeter.vue";
import GralTable from "../GralTable.vue";

export default {
  components: {
    GralTable,
    ClcReportDasboard,
    ClcReportDasboardDrill,
    ClcReportDasboardGral,
    ClcReportDasboardPriceMeter,
  },

  data() {
    return {
      currentItem: "tab-Funciones",
      processing: false,
      messageProcessing: "",
      txtYearFin: this.$fun.getYear(),
      txtYearInitial: 2012,

      itemsGeneral: [],

      itemsBatchCv: [],
      itemsAreaCv: [],
      itemsCapitalCv: [],
      itemsPricePerMeterCv: [],

      itemsBatchRe: [],
      itemsAreaRe: [],
      itemsCapitalRe: [],

      itemsBatchReFv: [],
      itemsAreaReFv: [],
      itemsCapitalReFv: [],

      itemsBatchBruta: [],
      itemsAreaBruta: [],
      itemsCapitalBruta: [],
      itemsPricePerMeterBruta: [],

      headersGeneral: [],
      headersBatchCv: [],
      headersAreaCv: [],
      headersCapitalCv: [],
      headersPricePerMeterCv: [],

      headersBatchRe: [],
      headersAreaRe: [],
      headersCapitalRe: [],

      headersAreaReFv: [],
      headersBatchReFv: [],
      headersCapitalReFv: [],

      headersBatchBruta: [],
      headersAreaBruta: [],
      headersCapitalBruta: [],
      headersPricePerMeterBruta: [],
      searcher: {
        capitalReFv: "",
        batchReFv: "",
        areaReFv: "",
        capitalBruta: "",
        batchBruta: "",
        areaBruta: "",
        capitalCv: "",
        batchCv: "",
        areaCv: "",
        pricePerMeterCv: "",
        pricePerMeterBruta: "",
        capitalRe: "",
        batchRe: "",
        areaRe: "",
        generalCV: "",
      },

      show: false,

      //Variables para Dashboard
      dataDashboardAreaCv: [],
      dataDashboardCapitalCv: [],
      dataDashboardLoteCV: [],
      dataDashboardAreaRe: [],
      dataDashboardCapitalRe: [],
      dataDashboardLoteRe: [],
      dataDashboardAreaReFv: [],
      dataDrilldownAreaReFv: [],
      dataDashboardCapitalReFv: [],
      dataDrilldownCapitalReFv: [],
      dataDashboardLoteReFv: [],
      dataDrilldownLoteReFv: [],
      dataDashboardAreaBruta: [],
      dataDashboardCapitalBruta: [],
      dataDashboardLoteBruta: [],
      objDrilldown: [],
      objDrilldownArea: [],
      objDrilldownCapital: [],
      objDrilldownLoteReFv: [],
      objDrilldownAreaReFv: [],
      objDrilldownCapitalReFv: [],
      objDrilldownLoteRe: [],
      objDrilldownAreaRe: [],
      objDrilldownCapitalRe: [],
      objDrilldownLoteCv: [],
      objDrilldownAreaCv: [],
      objDrilldownCapitalCv: [],
      idsRe: [4, 5, 6],
      idsReFv: [7, 8, 9],
      idsBruta: [10, 11, 12, 13],
      idsCv: [14, 15, 16, 17],
      itemsSalable: [],
      headerSalable: [],
      dataDashboardLoteCv: [],
      dataDashboardPricePerMeterCv: [],
      dataDashboardPricePerMeterBruta: [],
      objDrilldownLoteBruta: [],
      objDrilldownAreaBruta: [],
      objDrilldownCapitalBruta: [],
      objDrilldownPricePerMeterCv: [],
      objDrilldownPricePerMeterBruta: [],
      objMounth: null,
      disabledBtnDownload: true,
    };
  },

  methods: {
    run() {
      this.runViewCv();
      this.runViewGeneral();
      this.runViewRe();
      this.runViewReFechaVenta();
      this.runViewBruta();
      this.loadSalable();
      this.disabledBtnDownload = false;
    },

    runViewGeneral() {
      this.messageProcessing = "";
      var report = {};
      report.QryNameProcedure = "";
      report.QryParamsArray = "YearInitial, Yearfin,Period";
      report.QryParamsArrayValue =
        this.txtYearInitial +
        ", " +
        this.txtYearFin +
        "," +
        this.objMounth.DedAbbreviation;
      report.QryNameProcedure = "ClcReportUseGeneralCv_R";

      this.processing = true;
      _sQryConfigurationService
        .querygeneral(report, this.$fun.getUserID())
        .then(
          (resp) => {
            if (resp.status == 200) {
              this.processing = false;
              this.itemsGeneral = resp.data;
              this.headersGeneral = Object.keys(resp.data[0]).map((item) => ({
                text: item,
                value: item,
                width: "120",
                sortable: false,
                filterable: item === "Cod.venta" ? true : false,
              }));
            }
          },
          (e) => {
            this.messageProcessing = e.response.data.Message;
            this.processing = true;
          }
        );
    },
    runViewGral(nameProcedure) {
      return new Promise((resolve, reject) => {
        this.messageProcessing = "";
        var report = {};
        report.QryParamsArray = "YearInitial, Yearfin,Period";
        report.QryParamsArrayValue =
          this.txtYearInitial +
          ", " +
          this.txtYearFin +
          "," +
          this.objMounth.DedAbbreviation;
        report.QryNameProcedure = nameProcedure;

        this.processing = true;
        _sQryConfigurationService
          .querygeneral(report, this.$fun.getUserID())
          .then((resp) => {
            if (resp.status == 200) {
              let items, headers;
              this.processing = false;
              items = resp.data;
              headers = Object.keys(resp.data[0]).map((item) => ({
                text: item,
                value: item,
                width: "120",
                sortable: false,
              }));
              resolve({ items, headers });
            }
          })
          .catch((e) => {
            this.messageProcessing = e.response.data.Message;
            this.processing = true;
          });
      });
    },

    loadSalable() {
      this.messageProcessing = "";
      var report = {};
      report.QryParamsArray = "Period";
      report.QryParamsArrayValue = this.objMounth.DedAbbreviation;
      report.QryNameProcedure = "ClcReportUseSalable_R";

      this.processing = true;
      _sQryConfigurationService
        .querygeneral(report, this.$fun.getUserID())
        .then((resp) => {
          if (resp.status == 200) {
            this.processing = false;
            this.itemsSalable = resp.data.map((item) => {
              let tempuni = (item.UniDisponible / item.UniVendible) * 100;
              let tempmt2 = (item.M2Disponible / item.M2Vendible) * 100;
              tempuni = `${tempuni.toFixed(1)} %`;
              tempmt2 = `${tempmt2.toFixed(1)} %`;
              return {
                ...item,
                UniDispPorCiento: tempuni,
                M2DispPorCiento: tempmt2,
              };
            });
          }
        })
        .then(() => {
          this.headerSalable = Object.keys(this.itemsSalable[0])
            .filter((item) => item !== "cuadrante")
            .map((item) => ({
              text: item,
              value: item,
              width: "120",
              sortable: false,
            }));
        })
        .catch((e) => {
          this.messageProcessing = e.response.data.Message;
          this.processing = true;
        });
    },
    runViewReFechaVenta() {
      this.runViewGral("ClcReportUseAreaReFecVta_R").then((rpta) => {
        this.itemsAreaReFv = rpta.items;
        this.headersAreaReFv = rpta.headers;
        const { tempDasboard, tempDrilldown } = this.dasboardGral(rpta.items);
        this.dataDashboardAreaReFv = tempDasboard;
        this.objDrilldownAreaReFv = tempDrilldown;
      });
      this.runViewGral("ClcReportUseBacthReFecVta_R").then((rpta) => {
        this.itemsBatchReFv = rpta.items;
        this.headersBatchReFv = rpta.headers;
        const { tempDasboard, tempDrilldown } = this.dasboardGral(rpta.items);
        this.dataDashboardLoteReFv = tempDasboard;
        this.objDrilldownLoteReFv = tempDrilldown;
      });
      this.runViewGral("ClcReportUseCapitalReFecVta_R").then((rpta) => {
        this.itemsCapitalReFv = rpta.items;
        this.headersCapitalReFv = rpta.headers;
        const { tempDasboard, tempDrilldown } = this.dasboardGral(rpta.items);
        this.dataDashboardCapitalReFv = tempDasboard;
        this.objDrilldownCapitalReFv = tempDrilldown;
      });
    },
    runViewRe() {
      this.runViewGral("ClcReportUseAreaRe_R").then((rpta) => {
        this.itemsAreaRe = rpta.items;
        this.headersAreaRe = rpta.headers;
        const { tempDasboard, tempDrilldown } = this.dasboardGral(rpta.items);
        this.dataDashboardAreaRe = tempDasboard;
        this.objDrilldownAreaRe = tempDrilldown;
      });
      this.runViewGral("ClcReportUseBacthRe_R").then((rpta) => {
        this.itemsBatchRe = rpta.items;
        this.headersBatchRe = rpta.headers;
        const { tempDasboard, tempDrilldown } = this.dasboardGral(rpta.items);
        this.dataDashboardLoteRe = tempDasboard;
        this.objDrilldownLoteRe = tempDrilldown;
      });
      this.runViewGral("ClcReportUseCapitalRe_R").then((rpta) => {
        this.itemsCapitalRe = rpta.items;
        this.headersCapitalRe = rpta.headers;
        const { tempDasboard, tempDrilldown } = this.dasboardGral(rpta.items);
        this.dataDashboardCapitalRe = tempDasboard;
        this.objDrilldownCapitalRe = tempDrilldown;
      });
    },
    pricePerMeter(objArea, ArregloCapital, indexCapital) {
      const resByMonth = (month) =>
        objArea[month] === 0
          ? 0
          : Number(
              (ArregloCapital[indexCapital][month] / objArea[month]).toFixed(2)
            );
      return {
        ...objArea,
        Enero: resByMonth("Enero"),
        Febrero: resByMonth("Febrero"),
        Marzo: resByMonth("Marzo"),
        Abril: resByMonth("Abril"),
        Mayo: resByMonth("Mayo"),
        Junio: resByMonth("Junio"),
        Julio: resByMonth("Julio"),
        Agosto: resByMonth("Agosto"),
        Septiembre: resByMonth("Septiembre"),
        Octubre: resByMonth("Octubre"),
        Noviembre: resByMonth("Noviembre"),
        Diciembre: resByMonth("Diciembre"),
        Total: resByMonth("Total"),
      };
    },
    runViewCv() {
      this.runViewGral("ClcReportUseAreaCv_R").then((rpta) => {
        this.itemsAreaCv = rpta.items;
        this.headersAreaCv = rpta.headers;
        const { tempDasboard, tempDrilldown } = this.dasboardGral(rpta.items);
        this.dataDashboardAreaCv = [...tempDasboard];
        this.objDrilldownAreaCv = tempDrilldown;
      });
      this.runViewGral("ClcReportUseBacthCv_R").then((rpta) => {
        this.itemsBatchCv = rpta.items;
        this.headersBatchCv = rpta.headers;
        const { tempDasboard, tempDrilldown } = this.dasboardGral(rpta.items);
        this.dataDashboardLoteCv = tempDasboard;
        this.objDrilldownLoteCv = tempDrilldown;
      });
      this.runViewGral("ClcReportUseCapitalCv_R")
        .then((rpta) => {
          this.itemsCapitalCv = rpta.items;
          this.headersCapitalCv = rpta.headers;
          const { tempDasboard, tempDrilldown } = this.dasboardGral(rpta.items);
          this.dataDashboardCapitalCv = tempDasboard;
          this.objDrilldownCapitalCv = tempDrilldown;
        })
        .then(() => {
          this.itemsPricePerMeterCv = this.itemsAreaCv.map((valor, indice) =>
            this.pricePerMeter(valor, this.itemsCapitalCv, indice)
          );
        })
        .then(() => {
          this.headersPricePerMeterCv = Object.keys(
            this.itemsPricePerMeterCv[0]
          ).map((item) => ({
            text: item,
            value: item,
            width: "120",
            sortable: false,
          }));

          this.dataDashboardPricePerMeterCv = this.itemsPricePerMeterCv
            .filter((item) => item.Uso === "")
            .map((item) => ({
              drilldown: item.Anioventa,
              name: item.Anioventa,
              y: item.Total,
            }));
          this.objDrilldownPricePerMeterCv = this.itemsPricePerMeterCv
            .filter((item) => item.Uso === "")
            .map((item) => ({
              id: item.Anioventa,
              name: item.Anioventa,
              data: Object.entries(item).filter(
                (obj) => typeof obj[1] === "number" && obj[0] !== "Total"
              ),
            }));
        });
    },
    runViewBruta() {
      this.runViewGral("ClcReportUseAreaBruta_R").then((rpta) => {
        this.itemsAreaBruta = rpta.items;
        this.headersAreaBruta = rpta.headers;
        const { tempDasboard, tempDrilldown } = this.dasboardGral(rpta.items);
        this.dataDashboardAreaBruta = tempDasboard;
        this.objDrilldownAreaBruta = tempDrilldown;
      });
      this.runViewGral("ClcReportUseBacthBruta_R").then((rpta) => {
        this.itemsBatchBruta = rpta.items;
        this.headersBatchBruta = rpta.headers;
        const { tempDasboard, tempDrilldown } = this.dasboardGral(rpta.items);
        this.dataDashboardLoteBruta = tempDasboard;
        this.objDrilldownLoteBruta = tempDrilldown;
      });
      this.runViewGral("ClcReportUseCapitalBruta_R")
        .then((rpta) => {
          this.itemsCapitalBruta = rpta.items;
          this.headersCapitalBruta = rpta.headers;
          const { tempDasboard, tempDrilldown } = this.dasboardGral(rpta.items);
          this.dataDashboardCapitalBruta = tempDasboard;
          this.objDrilldownCapitalBruta = tempDrilldown;
        })
        .then(() => {
          this.itemsPricePerMeterBruta = this.itemsAreaBruta.map(
            (valor, indice) =>
              this.pricePerMeter(valor, this.itemsCapitalBruta, indice)
          );
        })
        .then(() => {
          this.headersPricePerMeterBruta = Object.keys(
            this.itemsPricePerMeterBruta[0]
          ).map((item) => ({
            text: item,
            value: item,
            width: "120",
            sortable: false,
          }));
          this.dataDashboardPricePerMeterBruta = this.itemsPricePerMeterBruta
            .filter((item) => item.Uso === "")
            .map((item) => ({
              drilldown: item.Anioventa,
              name: item.Anioventa,
              y: item.Total,
            }));
          this.objDrilldownPricePerMeterBruta = this.itemsPricePerMeterBruta
            .filter((item) => item.Uso === "")
            .map((item) => ({
              id: item.Anioventa,
              name: item.Anioventa,
              data: Object.entries(item).filter(
                (obj) => typeof obj[1] === "number" && obj[0] !== "Total"
              ),
            }));
        });
    },
    downloadOne() {
      const SheetNames = [
        "General CV|RE",
        "Lote Cv",
        "Area Cv",
        "Capital Cv",
        "Precio Promedio Cv",
        "Lote Re",
        "Area Re",
        "Capital Re",
        "Lote Re F Vta",
        "Area Re F Vta",
        "Capital Re F Vta",
        "Lote Bruta",
        "Area Bruta",
        "Capital Bruta",
        "Precio Promedio Bruta",
        "Disponible",
      ];
      const DataTables = [
        this.itemsGeneral,
        this.itemsBatchCv,
        this.itemsAreaCv,
        this.itemsCapitalCv,
        this.itemsPricePerMeterCv,
        this.itemsBatchRe,
        this.itemsAreaRe,
        this.itemsCapitalRe,
        this.itemsBatchReFv,
        this.itemsAreaReFv,
        this.itemsCapitalReFv,
        this.itemsBatchBruta,
        this.itemsAreaBruta,
        this.itemsCapitalBruta,
        this.itemsPricePerMeterBruta,
        this.itemsSalable,
      ];
      const dataSend = { DataTables, SheetNames };

      this.processing = true;
      _sQryConfigurationService
        .downloadexcelrc(dataSend, this.$fun.getUserID())
        .then(
          (r) => {
            if (r.status == 200) {
              this.processing = false;

              this.$fun.downloadFile(
                r.data,
                this.$const.TypeFile.EXCEL,
                `Usos ${this.objMounth.DedDescription}`
              );
            }
          },
          (e) => {
            this.messageProcessing = e.response.data.Message;
            this.processing = true;
          }
        );
    },

    dasboardGral(arreglo) {
      const items = _.groupBy(arreglo, "Anioventa");
      const tempDasboard = Object.entries(items).map((item) => ({
        name: item[0],
        y: item[1]
          .filter((row) => row.Uso !== "")
          .reduce((suma, temp) => suma + temp.Total, 0),
        drilldown: item[0],
      }));
      const tempDrilldown = Object.entries(items).map((item) => ({
        id: item[0],
        name: item[0],
        data: Object.entries(
          item[1]
            .filter((row) => row.Uso !== "")
            .reduce((suma, temp) => ({
              Enero: temp.Enero + suma?.Enero,
              Febrero: temp.Febrero + suma?.Febrero,
              Marzo: temp.Marzo + suma?.Marzo,
              Abril: temp.Abril + suma?.Abril,
              Mayo: temp.Mayo + suma?.Mayo,
              Junio: temp.Junio + suma?.Junio,
              Julio: temp.Julio + suma?.Julio,
              Agosto: temp.Agosto + suma?.Agosto,
              Septiembre: temp.Septiembre + suma?.Septiembre,
              Octubre: temp.Octubre + suma?.Octubre,
              Noviembre: temp.Noviembre + suma?.Noviembre,
              Diciembre: temp.Diciembre + suma?.Diciembre,
            }))
        ),
      }));
      return { tempDasboard, tempDrilldown };
    },
  },
};
</script>

<style >
.tran {
  opacity: 0.7;
}

.fixed-first-column thead tr,
.fixed-first-column tbody tr,
.fixed-second-column thead tr,
.fixed-second-column tbody tr {
  position: relative;
}
.fixed-first-column thead th:first-child,
.fixed-first-column tbody td:first-child,
.fixed-second-column thead th:nth-child(2),
.fixed-second-column tbody td:nth-child(2) {
  position: sticky;
  left: 0;
  background-color: white; /* Ajusta el fondo según tus necesidades */
  z-index: 1;
}
</style>
