<template>
  <div>
    <v-container>
      <v-container>
        <v-row>
          <v-col cols="12">
            <s-toolbar
              :color="'#244093'"
              dark
              label="Reporte de Usos"
              class="tran"
            ></s-toolbar>
              <clc-report-use-dinamic
              ></clc-report-use-dinamic>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    </div>
</template>

<script>
import ClcReportUseCarcode from "./ClcReportUseCarcode.vue";
import ClcReportUseDinamic from "./ClcReportUseDinamic.vue";
export default {
  components: {
    ClcReportUseCarcode,
    ClcReportUseDinamic,
  },

  data() {
    return {
      visible: true,
    };
  },

  methods: {
    inputType(item) {
      if (item !== undefined) {
        if (item == 1) {
          this.visible = true;
        } else {
          this.visible = false;
        }
      }
    },
  },
};
</script>

<style>
.tran {
  opacity: 0.7 !important;
}
</style>
