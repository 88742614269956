<template>
  <div>
    <v-container class="px-0">
      <v-col cols="12">
        <v-row>
          <v-col cols="12" lg="6" md="6">
            <high-charts-drill-bar
              :id="ids[0]"
              :items="itemsLot"
              :visibleSubTitle="true"
              :subtitle="'LOTES'"
              :objDrilldown="objDrilldownLot"
              :name="'Año'"
              :number="true"
            ></high-charts-drill-bar>
          </v-col>
          <v-col cols="12" lg="6" md="6">
            <high-charts-drill-bar
              :id="ids[1]"
              :items="itemsArea"
              :visibleSubTitle="true"
              :subtitle="'AREA'"
              :objDrilldown="objDrilldownArea"
              :name="'Año'"
              :valueSuffix="'M2'"
              :number="true"
              :thousandsSep="true"
            />
          </v-col>
          <v-col cols="12" lg="12" md="12" class="mt-3">
            <high-charts-drill-bar
              :id="ids[2]"
              :items="itemsCapital"
              :visibleSubTitle="true"
              :subtitle="'CAPITAL'"
              :objDrilldown="objDrilldownCapital"
              :name="'Año'"
              :valueSuffix="'USD'"
              :number="true"
              :thousandsSep="true"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import HighChartsDrillPie from "../../../components/Utils/HighCharts/HighChartsDrillPie.vue";

import HighChartsDrillBar from "@/components/Utils/HighCharts/HighChartsDrillBar.vue";
export default {
  props: {
    itemsLot: {
      type: Array,
      default: [],
    },
    objDrilldownLot: {
      type: Array,
      default: [],
    },

    itemsArea: {
      type: Array,
      default: [],
    },

    objDrilldownArea: {
      type: Array,
      default: [],
    },

    itemsCapital: {
      type: Array,
      default: [],
    },

    objDrilldownCapital: {
      type: Array,
      default: [],
    },
    ids: {
      type: Array,
      default: [],
    },
  },
  components: {
    HighChartsDrillPie,
    HighChartsDrillBar,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
.tran {
  opacity: 0.7 !important;
}
</style>
